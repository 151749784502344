import * as React from 'react';
import Grid from "@mui/material/Grid";
import NavBar from "./Navbar";
import Container from "@mui/material/Container";
import { Avatar, styled, SxProps, Theme } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import { useEffect, useState } from "react";
import OrdiaGlyphDisplay from "./OrdiaGlyphDisplay";

// Type for GlyphData fetched from the backend
interface GlyphData {
    glyph_id: string;
    fromAddress: string;
    toAddress: string;
    message: string;
    timestamp: string;
    txHash: string;
    from_address: string;
    to_address: string;
}

const CollectionBanner = styled(Container)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000124'),
    backgroundColor: '#000000',
    width: '100%',
    height: 'auto',
    fontSize: '1rem',
    borderRadius: '.75rem',
    textAlign: 'left',
    fontFamily: 'FWD',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    paddingBottom: '24px',
}));

const GlyphBanner = styled(Container)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000124'),
    width: '100%',
    height: 'auto',
    fontSize: '1rem',
    borderRadius: '48px',
    textAlign: 'left',
    fontFamily: 'FWD',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    paddingBottom: '24px',
    padding: '24px',
}));

const cardStyles: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'flex-start',
    fontFamily: 'FWD',
    p: 2,
    bgcolor: '#1A1A1A',
    borderColor: '#00FFFF',
    mb: 2,
    border: '1px solid #2c2c2c',
    borderRadius: '.75rem',
    textAlign: 'left',
    cursor: 'pointer',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    padding: '24px',
    '&:hover': {
        border: '1px solid #00BFFF',
        boxShadow: '0 0 4px #00BFFF',
    },
};

const LatestGlyphs = () => {
    const [glyphData, setGlyphData] = useState<GlyphData[]>([]); // Initialize as an empty array

    useEffect(() => {
        // Fetch glyph data from the new backend API
        const fetchGlyphData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/glyphs/latest`);
                if (!response.ok) throw new Error(`Failed to fetch glyphs: ${response.statusText}`);
                const data: GlyphData[] = await response.json();
                setGlyphData(data || []); // Ensure data is an array, default to empty array
            } catch (error) {
                console.error('Error fetching glyph data:', error);
            }
        };

        fetchGlyphData();
    }, []);

    return (
        <div>
            <NavBar isHub={false} />

            <Container disableGutters={true} maxWidth={false} style={{ borderBottom: '1px solid #000124', maxWidth: '1152px' }}>
                <Grid container spacing={4}>

                    <Grid item sm={12} md={12}>
                        <CollectionBanner>
                            <Grid container>
                                <Grid item sm={12} md={12}>
                                    <div style={{ padding: '24px', textAlign: 'center' }}>
                                        Latest Glyphs
                                    </div>
                                </Grid>

                                <Grid container style={{ background: '#1c1c1c', borderRadius: '24px' }}>
                                    <GlyphBanner>
                                        {glyphData && glyphData.length > 0 ? (
                                            glyphData.slice(0, 4).map((glyph, index) => (
                                                <Grid container key={index}>
                                                    <Grid item sm={2} md={2}>
                                                        <Container sx={cardStyles}>
                                                            <Avatar alt="Bitcoin Spacemen" src="/4.png" sx={{ width: '100%', height: '100%', mr: 2 }} />
                                                        </Container>
                                                    </Grid>

                                                    <Grid item sm={1} md={1}>
                                                        <div style={{ textAlign: 'center', position: 'relative', top: '50%', transform: 'translateY(-50%)', fontSize: '48px', fontFamily: 'FWD' }}>
                                                            {">"}
                                                        </div>
                                                    </Grid>

                                                    <Grid item sm={9} md={9}>
                                                        <OrdiaGlyphDisplay
                                                            username={glyph.from_address}
                                                            avatar={"./4.png"}
                                                            inscriptionNum={"0000"}
                                                            item={glyph.to_address}
                                                            date={"11/18/24"}
                                                            time={glyph.timestamp}
                                                            message={glyph.message}
                                                            txLink={`https://mempool.space/tx/${glyph.glyph_id}`}
                                                            size={"large"}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ))
                                        ) : (
                                            <div>No glyphs found</div>
                                        )}
                                    </GlyphBanner>
                                </Grid>
                            </Grid>
                        </CollectionBanner>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default LatestGlyphs;
