import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Container, Typography, CircularProgress, Alert, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ColoredButton from "./styled/ColoredButton";
import NavBar from "./Navbar";
import { useOrdConnect, useSignMessage } from "@ordzaar/ord-connect";
import { ButtonProps } from "@mui/material/Button";

const CollectionBanner = styled(Container)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000124'),
    backgroundColor: '#000000',
    width: '100%',
    height: 'auto',
    fontSize: '1rem',
    border: '1px solid #2c2c2c',
    borderRadius: '.75rem',
    textAlign: 'left',
    fontFamily: 'FWD',
    cursor: 'pointer',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    padding: '24px',
    minWidth: '25vw',

    '&:hover': {
        border: '1px solid #00BFFF',
        boxShadow: '0 0 4px #00BFFF',
    },
}));

const VerifyWallet: React.FC = () => {
    const location = useLocation();
    const [signed, setSigned] = useState<string | null>(null);
    const [verificationStatus, setVerificationStatus] = useState<string | null>(null);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const { address, publicKey } = useOrdConnect();
    const { signMsg } = useSignMessage();

    const query = new URLSearchParams(location.search);
    const addressToVerify = query.get('address');

    const handleSignMessage = async () => {
        if (!address.payments || signed || verificationStatus) return;

        try {
            const signedMessage = await signMsg(
                address.payments,
                `Verification for address: ${addressToVerify}`
            );
            setSigned(signedMessage);

            /*
            TODO: Verify a public address for a given collection
            await axios.post('/api/verify', {
                user_address: address.payments,
                signed_message: signedMessage,
                public_key: publicKey.payments,
                address_to_verify: addressToVerify,
            });
            */

            setVerificationStatus("Verification Successful");
        } catch (err) {
            setVerificationStatus("Verification Failed");
            setShowError(true);
            setErrorMsg('Verification failed, please try again.');
        }
    };

    useEffect(() => {
        if (addressToVerify && !verificationStatus) {
            handleSignMessage();
        }
    }, [addressToVerify, verificationStatus]);

    return (
        <Container>
            <NavBar isHub={true} />
            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                }}
            >
                <CollectionBanner>
                    <Container>
                        <div style={{ textAlign: 'center', padding: '24px' }}>
                            {verificationStatus ? (
                                <>
                                    <Typography variant="h6" style={{ padding: '24px 0' }}>
                                        {verificationStatus}
                                    </Typography>
                                    {verificationStatus === "Verification Successful" && (
                                        <CheckIcon style={{ color: '#4BFF72' }} />
                                    )}
                                    <div style={{ marginTop: '24px' }}>
                                        {
                                            verificationStatus === "Verification Successful" && (
                                                <Link to="/glyph">
                                                    <ColoredButton>Enter</ColoredButton>
                                                </Link>
                                            )
                                        }
                                    </div>
                                </>
                            ) : (
                                <>
                                    <CircularProgress color="inherit" style={{ marginBottom: '16px' }} />
                                    <Typography variant="h6" style={{ padding: '24px 0', fontFamily: 'FWD' }}>
                                        Verifying...
                                    </Typography>
                                </>
                            )}
                        </div>
                    </Container>
                </CollectionBanner>

            </Container>
            {showError && (
                <Alert severity="error" style={{ fontFamily: 'FWD', marginTop: '16px' }} onClose={() => setShowError(false)}>
                    Error: {errorMsg}
                </Alert>
            )}
        </Container>
    );
};

export default VerifyWallet;
