import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, Container, TextField, Button, Typography } from '@mui/material';
import Profile from "./components/Profile";
import { useOrdConnect } from "@ordzaar/ord-connect";
import { Collection } from "./types/Collection";
import CollectionLoader from "./components/CollectionLoader";
import Home from "./components/Home";
import Community from "./components/OrdiaHomePage";
import OrdCollection from "./components/OrdCollection";
import Inscriptions from "./components/Inscriptions";
import { DataProvider } from "./components/DataContext";
import ReactGA from 'react-ga4';
import VerifyWallet from "./components/VerifyWallet";
import GlyphWall from "./components/GlyphWall";
import ColoredButton from "./components/styled/ColoredButton";

const AppJs = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const hardCodedPassword = '808152'; // Replace with your hard-coded password

    ReactGA.initialize("G-EGRHFW2GK4")

    const [collectionMap, setCollectionMapState] = useState<Map<string,Collection>>(new Map());
    const setCollectionMap = (map: Map<string, Collection>) => {
        setCollectionMapState(map);
    }

    const [isHub, setIsHub] = useState(true);
    const { address } = useOrdConnect();

    // Check localStorage for authentication state on component mount
    useEffect(() => {
        const storedAuthState = localStorage.getItem('isAuthenticated');
        if (storedAuthState === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    const handleLogin = () => {
        if (password === hardCodedPassword) {
            setIsAuthenticated(true);
            localStorage.setItem('isAuthenticated', 'true'); // Persist authentication state
        } else {
            alert('Incorrect password. Please try again.');
        }
    };

    const containerStyle = {
        borderRadius: 2,
        p: 0,
        textAlign: 'center',
        color: '#f8f7f8',
        mt: 4,
        mb: 4,
    }

    if (!isAuthenticated) {
        return (
            <Container disableGutters={true} maxWidth={false}
                       style={{paddingTop: '12px', paddingLeft: '12px'}}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '25vh',
                    height: '20vh'
                }}>
                    <img src="/ordia_gif.gif" alt="logo" height={"120"}/>
                </div>
                <div style={{borderRadius: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5vh'}}>
                    <TextField
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="[code]"
                        sx={{backgroundColor: 'white', marginBottom: 2, width: '72px', textAlign: 'center'}}
                    />
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '12px'}}>
                    <ColoredButton onClick={handleLogin}>
                        Enter Code
                    </ColoredButton>
                </div>
            </Container>
        );
    }

    return (
        <DataProvider>
            <Container disableGutters={true} maxWidth={false}>
                {/*<Container disableGutters={true} maxWidth={false} style={{backgroundImage: `url(./Background.png)`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>*/}
                <Box component="div" sx={containerStyle}>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/hub" element={<Community/>} />
                        <Route path="/verify" element={<VerifyWallet/>} />
                        <Route path="/glyph" element={<GlyphWall/>} />
                        <Route path="/inscriptions" element={<Inscriptions/>} />
                        <Route path="/collection/:id" element={<OrdCollection/>} />
                        <Route path="/profile" element={<Profile setCollectionMap={setCollectionMap} isHub={isHub}/>} />
                        <Route path="/inscription/:id" element={<CollectionLoader collectionsMap={collectionMap} type={"image"} ownerAddress={address.ordinals} collectionAddress={""}/>} />
                    </Routes>
                </Box>
            </Container>
        </DataProvider>
    );
};

export default AppJs;
