import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppJs from './App.js.tsx';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {Network, Chain, OrdConnectProvider} from "@ordzaar/ord-connect";

// Create a theme instance.
const theme = createTheme({
    typography: {
        fontFamily: "Roboto, sans-serif",
    },
    palette: {
        background: {
            // midnight blue
            // default: '#000124',

            default: '#101220'
            // black
            // default: 'black',

            // other
            // default: '#121322'
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "#020218"
                },
            }
        },
        MuiGrid: {
            styleOverrides: {
                padding: '8px 8px'
            }
        },
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <OrdConnectProvider network={Network.MAINNET} chain={Chain.BITCOIN}>
            <ThemeProvider theme={theme}>
                <CssBaseline /> {/* This applies the baseline CSS including the background color */}
                <AppJs />
            </ThemeProvider>
        </OrdConnectProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
